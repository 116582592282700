'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Accordeon = function Accordeon() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slideToggles = [].slice.call(document.querySelectorAll('[action-accordeon]'));

    $slideToggles.forEach(function ($slideToggle) {
      $slideToggle.addEventListener('click', function () {
        var $currentBlock = document.querySelector('.content-block-' + $slideToggle.dataset.accordeonId);
        var $currentToggleWrapper = document.querySelector('.toggler-' + $slideToggle.dataset.accordeonId);

        $currentBlock.classList.toggle('is-visible');
        $currentToggleWrapper.classList.toggle('is-active');

        if ($currentToggleWrapper.classList.contains('is-active')) {
          $slideToggle.innerHTML = 'Voir moins';
        } else {
          $slideToggle.innerHTML = 'Voir plus';
        }
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();