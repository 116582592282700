'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Map = function Map() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * jQuery elements.
   */
  var $maps = [].slice.call(document.querySelectorAll('[data-map]'));
  var $mapAction = [].slice.call(document.querySelectorAll('.contact-information-wrapper'));
  var popup, Popup;

  /**
   * Initialize Google Maps instances.
   * @public
   */
  var initMaps = function initMaps(elements) {
    var $elements = elements;

    if (typeof $elements === 'undefined') {
      $elements = [].slice.call(document.querySelectorAll('[data-map]'));
    }

    console.log('--Here--');

    $elements.forEach(function ($map) {
      var address = false;
      var lat = false;
      var lng = false;
      var toggleCenter = false;

      // Check if address or coordinates are provided
      if (!toggleCenter) {
        if ($map.getAttribute('data-map-address') && $map.getAttribute('data-map-address') !== '') {
          address = $map.getAttribute('data-map-address');
        } else if ($map.getAttribute('data-map-lat') && $map.getAttribute('data-map-lng')) {
          lat = parseFloat($map.getAttribute('data-map-lat')) || false;
          lng = parseFloat($map.getAttribute('data-map-lng')) || false;
        }

        // Geocode the address or use provided coordinates
        if (address) {
          var geocoder = new google.maps.Geocoder();

          geocoder.geocode({ address: address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              var center = results[0].geometry.location;
              var map = new google.maps.Map($map, options);

              map.set('center', center);

              var marker = new google.maps.Marker({
                map: map,
                icon: '/img/site/icon-marker.svg',
                position: center
              });

              // infowindow.open(map,marker);

              Popup = createPopupClass();
              popup = new Popup(new google.maps.LatLng(lat, lng), document.getElementById('content'));
              popup.setMap(map);

              google.maps.event.addListener(marker, 'click', function () {
                window.open('https://www.google.ca/maps/dir//' + encodeURIComponent(address), '_blank');
              });
            }
          });
        } else if (lat && lng) {
          var center = new google.maps.LatLng(lat, lng);
          var map = new google.maps.Map($map, options);

          map.set('center', center);

          var marker = new google.maps.Marker({
            map: map,
            icon: '/img/site/icon-marker.svg',
            position: center
          });

          google.maps.event.addListener(marker, 'click', function () {
            window.open('https://www.google.ca/maps/dir//' + lat + ',' + lng, '_blank');
          });
        }
      }

      // Map options and styles
      var options = {
        zoom: 14,
        minZoom: 1,
        draggable: site.App.config('device') === 'desktop',
        scrollwheel: false,
        mapTypeControl: false,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        zoomControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: false,
        styles: [{
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{
            color: '#e9e9e9'
          }, {
            lightness: 17
          }]
        }, {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{
            color: '#f5f5f5'
          }, {
            lightness: 20
          }]
        }, {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 17
          }]
        }, {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 29
          }, {
            weight: 0.2
          }]
        }, {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 18
          }]
        }, {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 16
          }]
        }, {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{
            color: '#f5f5f5'
          }, {
            lightness: 21
          }]
        }, {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{
            color: '#dedede'
          }, {
            lightness: 21
          }]
        }, {
          elementType: 'labels.text.stroke',
          stylers: [{
            visibility: 'on'
          }, {
            color: '#ffffff'
          }, {
            lightness: 16
          }]
        }, {
          elementType: 'labels.text.fill',
          stylers: [{
            saturation: 36
          }, {
            color: '#333333'
          }, {
            lightness: 40
          }]
        }, {
          elementType: 'labels.icon',
          stylers: [{
            visibility: 'off'
          }]
        }, {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{
            color: '#f2f2f2'
          }, {
            lightness: 19
          }]
        }, {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{
            color: '#fefefe'
          }, {
            lightness: 20
          }]
        }, {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{
            color: '#fefefe'
          }, {
            lightness: 17
          }, {
            weight: 1.2
          }]
        }]
      };
    });
  };

  var createPopupClass = function createPopupClass() {
    /**
     * A customized popup on the map.
     * @param {!google.maps.LatLng} position
     * @param {!Element} content The bubble div.
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    function Popup(position, content) {
      this.position = position;

      content.classList.add('popup-bubble');

      // This zero-height div is positioned at the bottom of the bubble.
      var bubbleAnchor = document.createElement('div');
      bubbleAnchor.classList.add('popup-bubble-anchor');
      bubbleAnchor.appendChild(content);

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement('div');
      this.containerDiv.classList.add('popup-container');
      this.containerDiv.appendChild(bubbleAnchor);

      // Optionally stop clicks, etc., from bubbling up to the map.
      google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    }
    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function () {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function () {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function () {
      var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

      // Hide the popup when it is far out of view.
      var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

      if (display === 'block') {
        this.containerDiv.style.left = divPosition.x + 'px';
        this.containerDiv.style.top = divPosition.y + 'px';
      }
      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    };

    return Popup;
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Return success
    return true;
  };

  // Expose public methods & properties
  return {
    init: init,
    initMaps: initMaps
  };
}();